/* eslint-disable node/prefer-global/process */
import type { User } from "firebase/auth";
import { getAuth, signInAnonymously, signOut } from "firebase/auth";
import { useApolloClient } from "@vue/apollo-composable";
import { useMainStore } from "~/store";
import type { ActiveUserFragment } from "~/gql/graphql";
import { GetMeDocument } from "~/gql/graphql";

// middleware/auth.ts

const printMessages = false;

export async function ensureFirebaseUser(): Promise<User | null> {
    return getCurrentUser();
}

export async function ensureApolloInitialized(
    firebaseUser: User | null,
): Promise<boolean> {
    if (firebaseUser == null) {
        const { onLogout } = useApollo();
        await onLogout("auth");
        return true;
    }

    const firebaseToken = await firebaseUser.getIdToken();
    const { getToken } = useApollo();

    const apolloToken = await getToken("auth");
    if (firebaseToken !== apolloToken) {
        const { onLogin } = useApollo();
        await onLogin(firebaseToken, "auth", true);
        return true;
    }
    return false;
}

interface AuthParameters {
    inviteCode?: string;
    networkOnly?: boolean;
}

export async function refreshCiviqaUser() {
    const civiqaUserResult = await getCiviqaUser();
    useMainStore().civiqaUser = civiqaUserResult.civiqaUser ?? null;
    return useMainStore().civiqaUser;
}

export async function ensureCiviqaUserOnClient(
    parameters?: AuthParameters,
): Promise<CiviqaUserResult> {
    let civiqaUser: ActiveUserFragment | null = null;
    if (!parameters?.networkOnly) {
        civiqaUser = useMainStore().civiqaUser;
        if (civiqaUser) {
            return { civiqaUser: civiqaUser };
        }
    }
    try {
        const civiqaUserResult = await getCiviqaUser(parameters?.inviteCode);
        useMainStore().civiqaUser = civiqaUserResult.civiqaUser ?? null;
        return civiqaUserResult;
    } catch (error: any) {
        useMainStore().civiqaUser = null;
        return { error: error.message };
    }
}

export type CiviqaUserResult = {
    civiqaUser?: ActiveUserFragment;
    missingEnviroPerson?: boolean;
    email?: string;
    error?: string;
};

async function getCiviqaUser(inviteCode?: string): Promise<CiviqaUserResult> {
    const { resolveClient } = useApolloClient();
    const client = resolveClient("auth");

    let result;
    try {
        result = await client.query({
            query: GetMeDocument,
            variables: {
                input: {
                    inviteCode: inviteCode,
                },
            },
            fetchPolicy: "network-only",
        });
    } catch (error: any) {
        if (
            error.networkError &&
            error.networkError.result &&
            error.networkError.result.detail
        ) {
            return { error: error.networkError.result.detail };
        }
        throw error;
    }
    if (result.data?.me?.me) {
        return { civiqaUser: result.data.me?.me };
    }
    if (result.data?.me?.enviroPersonMissing) {
        return {
            missingEnviroPerson: true,
            email: result.data?.me?.email ?? undefined,
        };
    }
    if (result.errors) {
        return { error: result.errors[0].message };
    }
    return { error: "Unknown error" };
}
